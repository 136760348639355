<template>
  <div id="app" class="app">
    <transition-router>
      <router-view />
    </transition-router>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {

    }
  }
}
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
  background: #F4F5F8;
  font-family: -apple-system,BlinkMacSystemFont,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Segoe UI","PingFang SC","Hiragino Sans GB","Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
}
</style>
