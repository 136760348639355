'use strict'

import Vue from 'vue'
import axios from 'axios'
import baseUrl from '@/config'
import store from '@/store'
import { MessageBox, Message } from 'element-ui'
import { getToken, getTokenTime, setRefresh, setPath } from '@/utils/localStorage.js'
// import timeFormat from '@/utils/timeFormat.js'
// import router from '../modulars/Common/router' // 公共模块
// import router from '../modulars/Overview/router' // 数据总览
import router from '../modulars/DataPlatform/router' // 数据平台
// import router from '../modulars/OperationPlatform/router' // 运维平台
// import router from '../modulars/Middleware/router' // 业务中间件
// import router from '../modulars/System/router' // 系统管理

let config = {
  baseURL: baseUrl.baseUrl,
  timeout: 300000, // 超时时间设置为5min
  // withCredentials: true,
  headers: {
    'Cache-Control': 'no-cache,no-store,max-age=-1,private',
    'Content-Type': 'application/json;charset=utf-8'
  }
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  config => {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    let token = JSON.parse(getToken())
    if (!config.headers.hasOwnProperty('Authorization') && token) {
      config.headers['Authorization'] = token.accessToken
    }
    config.cancelToken = new axios.CancelToken(function (cancel) {
      store.commit('pushRequest', { cancelToken: cancel })
    })
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  response => {
    const res = response.data
    const tokenTime = getTokenTime() // token生成时间
    const timestamp = Date.parse(new Date()) // 当前时间
    const time = 900 // (单位秒) 这里要看后端的token过期时间来设置，例如：后端过期时间30分就不能超过30。
    // console.log({ tokenTime: timeFormat(tokenTime, 'YYYY-MM-DD HH:mm:ss') })
    // console.log({ nowTime: timeFormat(timestamp, 'YYYY-MM-DD HH:mm:ss') })
    // console.log({ tokenTime20: timeFormat((tokenTime + time * 1000), 'YYYY-MM-DD HH:mm:ss') })
    if (tokenTime && timestamp > (tokenTime + time * 1000)) {
      // 重新获取token
      store.dispatch('resetToken')
        .then((data) => {
          if (data.code !== 200) {
            // 刷新失败跳转登录
            // 保存除了登录页面的路径与参数
            if (window.location.href.split('#')[1] !== '/login') {
              setPath(window.location.href.split('#')[1])
            }
            MessageBox.confirm('您当前刷新token失败，请重新登录', '重新登录', {
              confirmButtonText: '重新登录',
              cancelButtonText: '取消',
              type: 'warning',
              closeOnClickModal: false
            }).then(() => {
              store.dispatch('logout')
              router.push({ name: 'login' })
            })
          }
        })
        .catch((error) => {
          // 刷新错误时
          console.log('失败', error)
        })
    } else if (res.code === 403) {
      console.log('暂无权限，需要被动登录')
      const isAsking = store.state.user.isAskLogin
      if (!isAsking) {
        store.commit('SET_IS_ASK_LOGIN', true)
        MessageBox.confirm('请联系管理员授权或切换其它账号重新登录', '权限不足', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          store.commit('SET_IS_ASK_LOGIN', false)
          store.dispatch('logout')
          router.push({ name: 'login' })
        }).catch(() => {
          store.commit('SET_IS_ASK_LOGIN', false)
        })
      }
    } else if (res.code === 4031) {
      console.log('4031错误：登录超时、未登录、token错误')
      // 提示重新登录时先保存页面路径与参数
      if (window.location.href.split('#')[1] !== '/login') {
        setPath(window.location.href.split('#')[1])
      }
      const isAsking = store.state.user.isAskLogin
      if (!isAsking) {
        store.commit('SET_IS_ASK_LOGIN', true)
        MessageBox.confirm('您当前已登录超时或未登录，请重新登录', '重新登录', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          store.commit('SET_IS_ASK_LOGIN', false)
          store.dispatch('logout')
          router.push({ name: 'login' })
        }).catch(() => {
          store.commit('SET_IS_ASK_LOGIN', false)
        })
      }
    } else if (res.code === 400) {
      console.log('400错误')
      console.log(res)
      Message.error(res.message)
    }

    return response.data
  },
  error => {
    setRefresh(false)
    return Promise.reject(error)
  }
)

Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      }
    },
    $axios: {
      get() {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default _axios
